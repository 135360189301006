<template>
  <div class="container">
    <div class="section">
      <div class="columns is-multiline">
        <div class="column is-12 title-section">
          <h1>Detalle de la vacante</h1>
          <div class="buttons">
            <button
            v-if="!isEdit"
            class="button is-primary is-outlined"
            @click="isEdit = !isEdit"
          >Editar</button>
           <button
            v-else
            class="button is-success is-outlined"
            :class="{ 'is-loading': loadingBtn }"
            :disabled="loadingBtn"
            @click="actualizarVacante"
          >Guardar</button>
          <button
            v-if="user.status === '1'"
            @click="eliminarVacante(vacanteId)"
            class="button is-primary"
          >Dar de baja</button>
          </div>
        </div>
        <div class="column is-12">
          <div class="card">
            <div class="columns is-multiline">
              <div class="column is-12">
                <h1 class="title-card">Información de la vacante</h1>
              </div>
              <div class="column is-4">
                <div class="field">
                  <label class="label">Nombre de la vacante</label>
                  <div class="control">
                    <input
                      v-if="isEdit"
                      v-model="newInfo.name"
                      class="input"
                      type="text"
                    >
                    <span v-else>
                      {{user.name}}
                    </span>
                  </div>
                </div>
              </div>
              <div class="column is-4">
                <div class="field">
                  <label class="label">Empresa</label>
                  <div class="control">
                    <input
                      v-if="isEdit"
                      v-model="newInfo.company"
                      class="input"
                      type="text"
                    >
                    <span v-else>
                      {{user.company}}
                    </span>
                  </div>
                </div>
              </div>
              <div class="column is-4">
                <div class="field">
                  <label class="label">Área</label>
                  <div class="control">
                    <input
                      v-if="isEdit"
                      v-model="newInfo.area"
                      class="input"
                      type="text"
                    >
                    <span v-else>
                      {{user.area}}
                    </span>
                  </div>
                </div>
              </div>
              <div class="column is-4">
                <div class="field">
                  <label class="label">Horario</label>
                  <div class="control">
                    <input
                      v-if="isEdit"
                      v-model="newInfo.schedule"
                      class="input"
                      type="text"
                    >
                    <span v-else>
                      {{user.schedule}}
                    </span>
                  </div>
                </div>
              </div>
              <div class="column is-4">
                <div class="field">
                  <label class="label">Ubicación</label>
                  <div class="select is-fullwidth" v-if="isEdit">
                    <select
                      class="select"
                      v-model="newInfo.location"
                    >
                      <option value="1">Sevilla</option>
                      <option value="2">Madrid</option>
                      <option value="3">Málaga</option>
                      <option value="4">Cadiz</option>
                      <option value="5">Huelva</option>
                      <option value="6">Almería</option>
                      <option value="7">Palencia</option>
                      <option value="8">Jaén</option>
                      <option value="9">Valladolid</option>
                      <option value="10">A Coruña</option>
                      <option value="11">Pontevedra</option>
                      <option value="12">Toledo</option>
                      <option value="13">Boiro</option>
                    </select>
                  </div>
                  <div class="control" v-else>
                    <span>
                      {{user.location}}
                    </span>
                  </div>
                </div>
              </div>
              <div class="column is-4">
                <div class="field">
                  <label class="label">Salario</label>
                  <div class="control">
                    <input
                      v-if="isEdit"
                      v-model="newInfo.salary"
                      class="input"
                      type="text"
                    >
                    <span v-else>
                      {{user.salary}}
                    </span>
                  </div>
                </div>
              </div>
              <div class="column is-12">
                <div class="field">
                  <label class="label">Descripción</label>
                  <div class="control">
                    <input
                      v-if="isEdit"
                      v-model="newInfo.description"
                      class="input"
                      type="text"
                    >
                    <span v-else>
                      {{user.description}}
                    </span>
                  </div>
                </div>
              </div>
              <div class="column is-12">
                <div class="field">
                  <label class="label">Requisitos</label>
                  <div class="control">
                    <input
                      v-if="isEdit"
                      v-model="newInfo.requirements"
                      class="input"
                      type="text"
                    >
                    <span v-else>
                      {{user.requirements}}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/libs/api'
export default {
  name: 'Vacante',
  data () {
    return {
      isEdit: false,
      vacanteId: this.$route.params.vacanteId,
      loading: false,
      loadingBtn: false,
      newInfo: {},
      user: {}
    }
  },
  methods: {
    async getVacanteById (vacantId) {
      const response = await api.getVacanteById(vacantId)
      if (response.success) {
        this.user = response.payload
        this.newInfo = { ...response.payload }
      }
    },
    async eliminarVacante (vacantId) {
      const response = await api.eliminarVacante(vacantId)
      if (response.success) {
        this.$router.push({ name: 'vancantes' })
      }
    },

    async actualizarVacante () {
      const vacanteId = this.$route.params.vacanteId
      const infoVacante = {
        name: this.newInfo.name,
        company: this.newInfo.company,
        area: this.newInfo.area,
        schedule: this.newInfo.schedule,
        location: this.newInfo.location,
        salary: this.newInfo.salary,
        description: this.newInfo.description,
        requirements: this.newInfo.requirements
      }
      this.loadingBtn = true
      const resp = await api.actualizarVacante(vacanteId, infoVacante)
      if (resp.success) {
        this.getVacanteById(vacanteId)
        this.isEdit = false
        this.loadingBtn = false
      }
    }
  },
  mounted () {
    this.loading = true
    this.getVacanteById(this.$route.params.vacanteId)
      .then(_ => {
        this.loading = false
      })
  }
}
</script>

<style lang="scss" scoped>
.card {
  padding-bottom: 3rem;
  .label {
    font-size: 14px;
    text-align: left;
    margin-left: 0.5rem;
  }
  .control {
    span {
      text-align: left;
      width: 100%;
      display: block;
      padding-left: 1rem;
      font-size: 13px;
    }
  }
}
</style>
